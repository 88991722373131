import Api from "@/api/Api";

export default {
  Get() {
    return Api().get("/product/attr");
  },
  GetAllAttribute() {
    return Api().get("/product/attr_list");
  },
  //   getDetail(id) {
  //     return Api().get(`//product/attr/${id}`);
  //   },
  //   GetOption/product/attrRole(role) {
  //     return Api().get(`//product/attr/get/product/attr?role=${role}`);
  //   },
  Update(id, data) {
    return Api().put(`/product/attr/${id}`, data);
  },
  addMultipleAttr(data) {
    return Api().post(`/product/attr/list/multiple`, data);
  },
  updateMultipleAttrList(data) {
    return Api().post(`/product/attr/list/multiple/update`, data);
  },
  addAttrList(data) {
    return Api().post(`/product/attr/list`, data);
  },
  Add(data) {
    return Api().post(`/product/attr/`, data);
  },
  Delete(id) {
    return Api().delete(`/product/attr/list/${id}`);
  },
  DeleteAttribute(id) {
    return Api().delete(`/product/attr/${id}`);
  },
  Details(id) {
    return Api().get(`/product/attr/${id}`);
  },
  DeleteMultiple(data) {
    return Api().post(`/product/attr/all`, data);
  },
  UploadImageList(id, data) {
    return Api().post(`/product/attr/list/upload/${id}`, data);
  },
};
